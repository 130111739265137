'use client';

import { Box, Button, Container, Typography } from '@mui/material';
export const SearchForPartsBanner = () => {
  const handleBtnClick = () => {
    window?.salesPilotPostMessages?.openSearchPage();
  };
  return <Container sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 10,
    mb: 12
  }} data-sentry-element="Container" data-sentry-component="SearchForPartsBanner" data-sentry-source-file="SearchForPartsBanner.tsx">
      <Typography component="h2" sx={{
      typography: {
        xs: 'h2',
        md: 'h1'
      },
      textAlign: 'center',
      mb: 2
    }} data-sentry-element="Typography" data-sentry-source-file="SearchForPartsBanner.tsx">
        Start shopping now!
      </Typography>
      <Box sx={{
      width: '100%',
      maxWidth: 'sm'
    }} data-sentry-element="Box" data-sentry-source-file="SearchForPartsBanner.tsx">
        <Button onClick={handleBtnClick} size="large" fullWidth sx={{
        bgcolor: 'extra.dark',
        color: 'common.white',
        '&:hover, &:focus': {
          bgcolor: 'extra.main',
          color: 'common.white'
        }
      }} data-sentry-element="Button" data-sentry-source-file="SearchForPartsBanner.tsx">
          Search for parts
        </Button>
      </Box>
    </Container>;
};