'use client';

import { CSSProperties } from 'react';
import { StaticImageData } from 'next/image';
import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardContent, Container, SxProps, Typography, useTheme } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { generateSPSPartCategorySearchURL } from '@/shared/utils/URLs';
import cablesAndConnectors from './assets/cablesAndConnectors.png';
import circuitBreakers from './assets/circuitBreakers.png';
import ioBlocks from './assets/ioBlocks.png';
import sensors from './assets/sensors.png';
import variableFrequencyDrives from './assets/variableFrequencyDrives.png';
type Category = {
  id: number;
  name: string;
  image: StaticImageData | null;
  url: string;
};
const hardcodedList: Omit<Category, 'url'>[] = [{
  id: 314,
  name: 'Miniature Circuit Breakers',
  image: circuitBreakers
}, {
  id: 614,
  name: 'PLC Modules',
  image: null
}, {
  id: 378,
  name: 'Cables and Connectors',
  image: cablesAndConnectors
}, {
  id: 388,
  name: 'Proximity Sensors',
  image: sensors
}, {
  id: 360,
  name: 'Variable\nFrequency Drives',
  image: variableFrequencyDrives
}, {
  id: 612,
  name: 'I/O\nBlocks',
  image: ioBlocks
}];
export const TrendingCategories = () => {
  const isMobile = useIsMobile();
  const list: Category[] = hardcodedList.map(hardcodedCategory => {
    return {
      ...hardcodedCategory,
      url: generateSPSPartCategorySearchURL(hardcodedCategory.name, hardcodedCategory.id)
    };
  });
  return <Box component="section" data-sentry-element="Box" data-sentry-component="TrendingCategories" data-sentry-source-file="TrendingCategories.tsx">
      <Container sx={{
      py: {
        xs: 4,
        md: 6
      }
    }} data-sentry-element="Container" data-sentry-source-file="TrendingCategories.tsx">
        <Typography component="h2" variant="h3" sx={{
        mb: {
          xs: 3,
          md: 4
        }
      }} data-sentry-element="Typography" data-sentry-source-file="TrendingCategories.tsx">
          Trending categories
        </Typography>
        <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',
          md: 'repeat(6, 1fr)'
        },
        gridTemplateRows: {
          xs: 'repeat(4, 176px)',
          md: 'repeat(2, 146px)'
        },
        gap: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }} data-sentry-element="Box" data-sentry-source-file="TrendingCategories.tsx">
          <CategoryCard category={list[0]} sx={{
          gridColumn: {
            xs: 'span 2',
            md: 'span 2'
          },
          gridRow: {
            xs: 'span 1',
            md: 'span 2'
          }
        }} contentWrapperSx={{
          alignItems: 'flex-end'
        }} textWrapperSx={{
          maxWidth: 100
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[1]} sx={{
          gridColumn: 'span 1',
          gridRow: 'span 1'
        }} contentWrapperSx={{
          pr: 3,
          bgcolor: 'common.black'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[2]} sx={{
          gridColumn: 'span 1'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[3]} sx={{
          gridColumn: 'span 2'
        }} textWrapperSx={{
          maxWidth: 120
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[4]} sx={{
          gridColumn: {
            xs: 'span 1',
            md: 'span 2'
          }
        }} contentWrapperSx={{
          alignItems: 'center'
        }} textWrapperSx={{
          maxWidth: 180
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[5]} sx={{
          gridColumn: 'span 1'
        }} contentWrapperSx={{
          whiteSpace: 'pre-line',
          alignItems: 'center'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />

          {isMobile ? <Button href="/search/partCategory" variant="contained" endIcon={<ChevronRight />} size="large" sx={{
          gridColumn: 'span 2',
          mt: 2
        }}>
              View all categories
            </Button> : <Card sx={theme => ({
          backgroundColor: 'common.black',
          gridColumn: 'span 1',
          transition: theme.transitions.create(['background-color', 'font-weight']),
          '&:hover': {
            backgroundColor: 'primary.dark',
            '& p': {
              fontWeight: 600
            }
          }
        })}>
              <CardActionArea href="/search/partCategory" sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}>
                <Typography fontSize={15} fontWeight={500} color="common.white">
                  All categories
                </Typography>
                <ChevronRight sx={{
              ml: 1,
              color: 'common.white'
            }} />
              </CardActionArea>
            </Card>}
        </Box>
      </Container>
    </Box>;
};
type CategoryCardProps = {
  category: Category;
  sx?: SxProps;
  noImage?: boolean;
  imageWrapperSx?: SxProps;
  imageCSSProperties?: CSSProperties;
  contentWrapperSx?: SxProps;
  textWrapperSx?: SxProps;
};
const CategoryCard = ({
  category,
  sx,
  contentWrapperSx = {},
  textWrapperSx = {}
}: CategoryCardProps) => {
  const theme = useTheme();
  return <Card sx={{
    position: 'relative',
    height: '100%',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'primary.4p',
    backgroundImage: category.image ? `url(${category.image.src})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: theme.transitions.create(['background-color', 'font-weight']),
    '&:hover': {
      backgroundColor: 'primary.50p',
      '& h3': {
        fontWeight: 700
      }
    },
    ...sx
  }} data-sentry-element="Card" data-sentry-component="CategoryCard" data-sentry-source-file="TrendingCategories.tsx">
      <CardActionArea href={category.url} sx={{
      height: '100%',
      width: '100%'
    }} data-sentry-element="CardActionArea" data-sentry-source-file="TrendingCategories.tsx">
        <CardContent sx={{
        p: 2,
        gap: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
          pb: 0
        },
        ...contentWrapperSx
      }} data-sentry-element="CardContent" data-sentry-source-file="TrendingCategories.tsx">
          <Typography variant="h4" component="h3" sx={{
          width: 'fit-content',
          color: 'common.white',
          ...textWrapperSx
        }} data-sentry-element="Typography" data-sentry-source-file="TrendingCategories.tsx">
            {category.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>;
};